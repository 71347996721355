import { EditorProps } from "@monaco-editor/react";

export const options: EditorProps["options"] = {
  automaticLayout: true,
  tabSize: 2,
  scrollBeyondLastLine: false,
  minimap: {
    enabled: false,
  },
  scrollbar: {
    vertical: "auto",
  },
};

export const darkTheme = {
  base: "vs-dark",
  inherit: true,
  rules: [],
  colors: {
    "activityBar.background": "#23272e",
    "activityBar.foreground": "#d7dae0",
    "activityBarBadge.background": "#4d78cc",
    "activityBarBadge.foreground": "#f8fafd",
    "badge.background": "#23272e",
    "button.background": "#404754",
    "button.secondaryBackground": "#30333d",
    "button.secondaryForeground": "#c0bdbd",
    "checkbox.border": "#404754",
    "debugToolBar.background": "#1e2227",
    descriptionForeground: "#abb2bf",
    "diffEditor.insertedTextBackground": "#00809b33",
    "dropdown.background": "#1e2227",
    "dropdown.border": "#1e2227",
    "editor.background": "#23272e",
    "editor.findMatchBackground": "#42557b",
    "editor.findMatchBorder": "#457dff",
    "editor.findMatchHighlightBackground": "#6199ff2f",
    "editor.foreground": "#abb2bf",
    "editorBracketHighlight.foreground1": "#d19a66",
    "editorBracketHighlight.foreground2": "#c678dd",
    "editorBracketHighlight.foreground3": "#56b6c2",
    "editorHoverWidget.highlightForeground": "#61afef",
    "editorInlayHint.foreground": "#abb2bf",
    "editorInlayHint.background": "#2c313c",
    "editor.lineHighlightBackground": "#2c313c",
    "editorLineNumber.activeForeground": "#abb2bf",
    "editorGutter.addedBackground": "#109868",
    "editorGutter.deletedBackground": "#9A353D",
    "editorGutter.modifiedBackground": "#948B60",
    "editorOverviewRuler.addedBackground": "#109868",
    "editorOverviewRuler.deletedBackground": "#9A353D",
    "editorOverviewRuler.modifiedBackground": "#948B60",
    "editor.selectionBackground": "#67769660",
    "editor.selectionHighlightBackground": "#ffffff10",
    "editor.selectionHighlightBorder": "#dddddd",
    "editor.wordHighlightBackground": "#d2e0ff2f",
    "editor.wordHighlightBorder": "#7f848e",
    "editor.wordHighlightStrongBackground": "#abb2bf26",
    "editor.wordHighlightStrongBorder": "#7f848e",
    "editorBracketMatch.background": "#515a6b",
    "editorBracketMatch.border": "#515a6b",
    "editorCursor.background": "#ffffffc9",
    "editorCursor.foreground": "#528bff",
    "editorError.foreground": "#c24038",
    "editorGroup.background": "#181a1f",
    "editorGroup.border": "#181a1f",
    "editorGroupHeader.tabsBackground": "#1e2227",
    "editorHoverWidget.background": "#1e2227",
    "editorHoverWidget.border": "#181a1f",
    "editorIndentGuide.activeBackground": "#c8c8c859",
    "editorIndentGuide.background": "#3b4048",
    "editorLineNumber.foreground": "#495162",
    "editorMarkerNavigation.background": "#1e2227",
    "editorRuler.foreground": "#abb2bf26",
    "editorSuggestWidget.background": "#1e2227",
    "editorSuggestWidget.border": "#181a1f",
    "editorSuggestWidget.selectedBackground": "#2c313a",
    "editorWarning.foreground": "#d19a66",
    "editorWhitespace.foreground": "#ffffff1d",
    "editorWidget.background": "#1e2227",
    focusBorder: "#3e4452",
    "gitDecoration.ignoredResourceForeground": "#636b78",
    "input.background": "#1d1f23",
    "input.foreground": "#abb2bf",
    "list.activeSelectionBackground": "#2c313a",
    "list.activeSelectionForeground": "#d7dae0",
    "list.focusBackground": "#323842",
    "list.focusForeground": "#f0f0f0",
    "list.highlightForeground": "#ecebeb",
    "list.hoverBackground": "#2c313a",
    "list.hoverForeground": "#abb2bf",
    "list.inactiveSelectionBackground": "#323842",
    "list.inactiveSelectionForeground": "#d7dae0",
    "list.warningForeground": "#d19a66",
    "menu.foreground": "#abb2bf",
    "menu.separatorBackground": "#343a45",
    "minimapGutter.addedBackground": "#109868",
    "minimapGutter.deletedBackground": "#9A353D",
    "minimapGutter.modifiedBackground": "#948B60",
    "panel.border": "#3e4452",
    "panelSectionHeader.background": "#1e2227",
    "peekViewEditor.background": "#1b1d23",
    "peekViewEditor.matchHighlightBackground": "#29244b",
    "peekViewResult.background": "#22262b",
    "scrollbar.shadow": "#23252c",
    "scrollbarSlider.activeBackground": "#747d9180",
    "scrollbarSlider.background": "#4e566660",
    "scrollbarSlider.hoverBackground": "#5a637580",
    "settings.focusedRowBackground": "#23272e",
    "settings.headerForeground": "#fff",
    "sideBar.background": "#1e2227",
    "sideBar.foreground": "#abb2bf",
    "sideBarSectionHeader.background": "#23272e",
    "sideBarSectionHeader.foreground": "#abb2bf",
    "statusBar.background": "#1e2227",
    "statusBar.debuggingBackground": "#cc6633",
    "statusBar.debuggingBorder": "#ff000000",
    "statusBar.debuggingForeground": "#ffffff",
    "statusBar.foreground": "#9da5b4",
    "statusBar.noFolderBackground": "#1e2227",
    "statusBarItem.remoteBackground": "#4d78cc",
    "statusBarItem.remoteForeground": "#f8fafd",
    "tab.activeBackground": "#23272e",
    "tab.activeBorder": "#b4b4b4",
    "tab.activeForeground": "#dcdcdc",
    "tab.border": "#181a1f",
    "tab.hoverBackground": "#323842",
    "tab.inactiveBackground": "#1e2227",
    "tab.unfocusedHoverBackground": "#323842",
    "terminal.ansiBlack": "#3f4451",
    "terminal.ansiBlue": "#4aa5f0",
    "terminal.ansiBrightBlack": "#4f5666",
    "terminal.ansiBrightBlue": "#4dc4ff",
    "terminal.ansiBrightCyan": "#4cd1e0",
    "terminal.ansiBrightGreen": "#a5e075",
    "terminal.ansiBrightMagenta": "#de73ff",
    "terminal.ansiBrightRed": "#ff616e",
    "terminal.ansiBrightWhite": "#e6e6e6",
    "terminal.ansiBrightYellow": "#f0a45d",
    "terminal.ansiCyan": "#42b3c2",
    "terminal.ansiGreen": "#8cc265",
    "terminal.ansiMagenta": "#c162de",
    "terminal.ansiRed": "#e05561",
    "terminal.ansiWhite": "#d7dae0",
    "terminal.ansiYellow": "#d18f52",
    "terminal.background": "#23272e",
    "terminal.border": "#3e4452",
    "terminal.foreground": "#abb2bf",
    "terminal.selectionBackground": "#abb2bf30",
    "textBlockQuote.background": "#2e3440",
    "textBlockQuote.border": "#4b5362",
    "textLink.foreground": "#61afef",
    "textPreformat.foreground": "#d19a66",
    "titleBar.activeBackground": "#23272e",
    "titleBar.activeForeground": "#9da5b4",
    "titleBar.inactiveBackground": "#23272e",
    "titleBar.inactiveForeground": "#6b717d",
    "tree.indentGuidesStroke": "#ffffff1d",
    "walkThrough.embeddedEditorBackground": "#2e3440",
    "welcomePage.buttonHoverBackground": "#404754",
  },
};
